import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';

import { centerContent, linkStyle } from '../theme/common'

const About: React.FC = () => {

    return (
      <Box sx={centerContent}>

            <Typography sx={{
                fontSize:35,
                fontFamily: "Southbank",
                color: "black",
                marginTop: 10,
                }}
            >

                <p><span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span> is a unique network, music and dance experience with a focus on cultivating environments for collaborative self-expression, discovery and growth.</p>

                <p><span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span> takes strong cues from those who have pioneered similar efforts, such as <a style={linkStyle} href="https://en.wikipedia.org/wiki/David_Mancuso" target="_new">David Mancuso</a>'s widely influential <a style={linkStyle} href="https://en.wikipedia.org/wiki/The_Loft_(New_York_City)" target="_new">The Loft</a> in the burgeoning 1960s and 1970s New York City dance scene and <a style={linkStyle} href="https://en.wikipedia.org/wiki/Danny_Howells" target="_new">Danny Howells</a>'s magical Arc/Vinyl NYC marathon sets.</p>

                <p>With extended running times of 12 hours and beyond, <span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span> provides a vast expanse for participants to stretch and explore the people and space around them. Expressions of all sorts may flourish here, but dance serves as the main connective tissue, with the "musical host" guiding the journey via careful selections across myriad different genres, all lovingly chosen to move the bodies, open the minds and connect the souls.</p>

                <p>The <span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span> events are private and invite only. Members are encouraged to bring in new people and the collective overwhelmingly welcomes all interested individuals.</p>
            
                <p>We reach inwards.</p>
                <p>We reach outwards.</p>
                <p> We create bonds.</p>
                <p>We are <br/>
                <span style={{backgroundColor: "rgba(255,0,0,0.5)", fontSize:45}}> <strong>Together Forever</strong></span>.</p>

                <Box style={{height:"40vh"}}></Box>

        </Typography>

      </Box>

  );

}

export default About;


