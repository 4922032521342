import { CSSProperties } from 'react';

export const centerContent = {
    display: "table-cell",
    width: "100vw",
    height: "100vh",
    verticalAlign: "middle",
    textAlign: "-webkit-center",
}

export const linkStyle: CSSProperties = {
    color: "#000",
  } 