import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';

// import { useGlobalState } from '../store/store'

import { centerContent } from '../theme/common'

const Account: React.FC = () => {

    return (
      <Box sx={centerContent}>
        

        <Typography sx={{fontSize:45, fontFamily: "Southbank", color: "black"}}>

            The members portal will be available soon.

        </Typography>

      </Box>
  );

}

export default Account;

