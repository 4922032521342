import React, { CSSProperties, useEffect } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MailIcon from '@mui/icons-material/Mail'
import AboutIcon from '@mui/icons-material/Info'
import HomeIcon from '@mui/icons-material/Home'
import AccountIcon from '@mui/icons-material/AccountCircle'
import RefreshIcon from '@mui/icons-material/RefreshOutlined'
import Tooltip from '@mui/material/Tooltip'
import TwitterIcon from '@mui/icons-material/Twitter'

import { useNavigate } from "react-router-dom";

import './App.css';

import Routes from "./routes";

import { useGlobalState } from './store/store'
import { CircularProgress } from '@mui/material';

const App: React.FC = () => {

    const navigate = useNavigate()
    const currentTheme = useTheme()

    const [bgUrl, setBgUrl] = React.useState('')

    const [loading, setLoading] = useGlobalState('loading')
    const [mainBackgroundImageUrl] = useGlobalState('mainBackgroundImageUrl')
    const [staticBackgroundImageUrls] = useGlobalState('staticBackgroundImageUrls')

    const [twitterUrl] = useGlobalState('twitterUrl')
    
    const barRight = {
        marginLeft: 0,
        display: 'flex',
        width: '100vw',
        justifyContent: 'flex-end',
    }

    const toolbar = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: currentTheme.spacing(0, 1),
        ...(currentTheme: Theme) => currentTheme.mixins.toolbar
    }

    const appStyle: CSSProperties = {
      display: "flex",
      margin: 0,
      height: "100vh",
      width: "100wh",
      textAlign: "center",
      opacity: 0.9,
      backgroundImage: 'url(' + bgUrl + ')',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      overflowX: "auto"
    }

    const refreshMedia = () => {

        setLoading(true)

        // fetch(unsplashSourceUrl)
        //   .then(response => { 
        //       console.log("Background:", response.url);
        //       setMainBackgroundImageUrl(response.url)
        //       setLoading(false)
        // }).catch(error => {
        //       console.log("Background: default")
        //       setLoading(false)
        // });

        setBgUrl(staticBackgroundImageUrls[Math.floor(Math.random()*staticBackgroundImageUrls.length)])
        
        setLoading(false)

    }

    useEffect(() => {
    
        refreshMedia()

      },[])

    return (

        <Box style={appStyle}>

        <Box>

            <CssBaseline />

            <AppBar
                position="fixed"
                sx={{
                    backgroundColor: "rgba(0,0,0,0.5)"
                }}
            >
 
                <Toolbar>
                    <IconButton
                        color="inherit"
                        onClick={() => navigate('/')}
                        edge="start"
                    >
                        <HomeIcon/>
                    </IconButton>
                    <Box sx={barRight}>
                        <IconButton
                        color="inherit"
                        onClick={() => navigate('about')}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            <AboutIcon/>
                        </IconButton>
                        <IconButton
                        color="inherit"
                        onClick={() => navigate('contact')}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            <MailIcon/>
                        </IconButton>
                        <IconButton
                        color="inherit"
                        onClick={refreshMedia}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            {loading ? <CircularProgress size='1em'/> : <RefreshIcon />}
                            {/* <CircularProgress size='1em'/> */}
                        </IconButton>

                        {/* <IconButton
                        color="inherit"
                        onClick={() => window.open(twitterUrl, '_blank')}
                        edge="start"
                        sx={{
                            marginRight: 2
                        }}
                        >
                            <TwitterIcon/>
                        </IconButton> */}

                        <IconButton
                        color="inherit"
                        onClick={() => navigate('account')}
                        edge="start"
                        >
                            <AccountIcon/>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            <main style={{
                flexGrow: 1,
                padding: currentTheme.spacing(3),
            }}>
                <Box sx={toolbar} />
                    <Routes />
            </main>
        </Box>

        <Tooltip 
        title={mainBackgroundImageUrl}
        >
            <IconButton
                style={{
                position: "absolute",
                bottom: 0,
                right: 0
                }}
                onClick={() => window.open(bgUrl)}>
            <AboutIcon
                fontSize="large"
            />
            </IconButton>
        </Tooltip>


        </Box>

  );

}

export default App;

