import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';

import { centerContent, linkStyle } from '../theme/common'

const Contact: React.FC = () => {

    return (
      <Box sx={centerContent}>
        
        <Typography sx={{fontSize:35, fontFamily: "Southbank", color: "black"}}>
        If you would like more information about the <span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span> experience, 
        including sponsoring a future event, please reach out via <span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <a style={linkStyle} href="mailto:weare@togetherforever.fm">weare@togetherforever.fm</a></span>.
        </Typography>

      </Box>
  );

}

export default Contact;

