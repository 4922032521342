import React from 'react';
import Box from '@mui/material/Box'

import { centerContent } from '../theme/common'

const NotFound: React.FC = () => {

    return (
      <Box sx={centerContent}>

            <span style={{backgroundColor:"rgba(255,0,0,0.5"}}>nothing here, sorry.</span>

      </Box>

  );

}

export default NotFound;

