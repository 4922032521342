import React from 'react';
import Box from '@mui/material/Box'
import { Typography } from '@mui/material';

// import { useGlobalState } from '../store/store'

import { centerContent } from '../theme/common'

const Home: React.FC = () => {

    return (
      <Box sx={centerContent}>
        
        <Typography sx={{fontSize:75, fontFamily: "Southbank", color: "black"}}>
          WE ARE <span style={{backgroundColor: "rgba(255,0,0,0.5)"}}> <strong>Together Forever</strong></span>
        </Typography>

      </Box>
  );

}

export default Home;

